
// Color

$main-color: #F38181;
$secondary-color: #95E1D3;
$button-color: #e34e09;
$title-color: #303133;
$p-color: #777777;
$footer-color: #333644;


//#79A8A9
//#1F4E5F
//#333644;

// Media Queries

$xxs: 375px;
$xs: 575px;
$sm: 767px;
$md: 991px;
$lg: 1150px;
