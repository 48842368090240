@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700');

@import "_normalize.scss";
@import "_grid.scss";
@import "_variables.scss";
@import "_mixins.scss";
@import "_input.scss";

@import "_responsivemenu.scss";


//// BASE STYLES

*,
*:after,
*:before {
	box-sizing: border-box;

}
html {
	font-size: 62.5%;
	height: 100%;
}

body {
	font-size: 1.5em;
	line-height: 1.6;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	
}

*:focus {
	outline: none;
}


a {
  text-decoration: none;
  transition: all 0.3s linear 0s;
}

li {
	list-style: none;
	
}

ul {
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
}







///////////////

// NAVBAR

///////////////



.navbar {
	z-index: 999999;

	@media (max-width: 991px) {
		.wrapper {
			padding-top: 0.5rem;
		}
	}



	.container {
		display: flex;
		justify-content: space-between;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;


		> * {
			align-self: center;
			margin: 0;
		}

		.mobile-menu-trigger {
			display: none;
			margin-right: auto;
		}

		.logo {
			flex-shrink: 0;
			margin-right: auto;
			color: $main-color;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 3rem;

			&:hover {
				color: #fff;
			}

			@media (max-width: $sm) {
				font-size: 2.5rem;
			}

			@media (max-width: $xxs) {
				font-size: 2.3rem;
			}
		}

		nav {
			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;

				li {
					display: inline-block;
					z-index: 999999;
					position: relative;

					a {
						display: block;
						padding: 2rem 2rem;
						color: #fff;
						text-transform: uppercase;
						font-size: 1.5rem;
						letter-spacing: 0.5px;


						&:hover {
							color: $main-color;
							transition: 0.3s;
						}

						@media (max-width: $lg) {
							padding: 2rem 1.5rem;
						}

						@media (max-width: 1000px) {
							padding: 2rem 1.3rem;
						}

					}

					ul {
						
						position: absolute;
						top: 100%;
						left: 0%;
						width: 200px;
						background-color: rgba($main-color, 0.8);
						transform: scale(1, 0);
						transform-origin: center top;
						opacity: 0;
						// transition: visibility linear 0.1s, opacity 0.1s;

						transition: opacity ease 0.25s;

						li {
							display: block;

							&:hover {
								background-color: rgba(white, 0.7);
							}

							a {
								font-size: 1.4rem;
							}

							> ul {
								
								left: 100%;
								top: 0;
								transform: scale(1,0);
								transform-origin: center top;
								// transition: visibility 0s linear 0.1s;

							}

							
						}

					} 

					&:hover {
						> ul {
							transform: scale(1,1);
							opacity: 1;

						}
					}
				}
			}
		}

		@media (max-width: 991px) {
			padding: 10px 5vw;

			.mobile-menu-trigger {
				display: block;
			}

			nav {
				display: none;
			}

			.logo {
				margin-right: 0;

			}
		}
	}


	//////// Styling for Nav When Sticky

	.active {
	  position: fixed;
	  width: 100%;
	  left: 0;
	  top: 0;
	  z-index: 100;
	  border-top: 0;
	  background-color: #fff;
	  box-shadow: 0px 8px 6px -6px #999;

	  .logo {

	  	&:hover {
	  		color: rgba($main-color, 0.7);
	  	}

	  }
	  nav {
	  	ul {
	  		li {
	  			a {
	  				color: $main-color;
	  				padding: 1.5rem 2rem;

	  				&:hover {
	  					color: rgba($main-color, 0.7);
	  				}

	  				@media (max-width: $lg) {
	  					padding: 2rem 1.5rem;
	  				}
	  			
	  			}

	  			ul {
	  				background-color: rgba($main-color, 0.85);

	  				li {
	  					a {
	  						color: #fff;
	  						padding: 2rem 2rem;

	  						&:hover {
	  							color: $main-color;
	  						}
	  					}
	  				}
	  			}
	  		}
	  	}
	  }
	}
}


//////// Mobile Menu with Sticky Nav

@media (max-width: 991px) {
	.navbar {
		.active {
			background-color: transparent;
			box-shadow: none;

			.logo {
				display: none;
			}
		}

		.mobile-nav {
			padding: 1rem;
			border-radius: 50%;
			background-color: rgba(white, 0.8);

			.mobile-menu-trigger {

				span {
					background-color: $main-color;
				}
			}
		}
	}
}






//////////
// MAIN HEADER
//////////

#home {

.main-header {
	background: url('../images/hero-main.jpg');
	height: 700px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	@media (max-width: $lg) {
		height: 600px;
	}

	@media (max-width: $md) {
		height: 520px;
	}

	@media (max-width: $sm) {
		height: 460px;
	}

	@media (max-width: $xs) {
		height: 430px;
	}

	@media (max-width: 479px) {
		height: 500px;
	}
}

.navbar {
	
	.hero-text {
		width: 90%;
		max-width: 1170px;
		margin: 0 auto;
		margin-top: 11rem;
		text-align: left;

		@media (max-width: $sm) {
			margin-top: 8rem;
		}

		h1.smaller-title {
			font-size: 3.3rem;

			@media (max-width: $lg) {
				font-size: 3.1rem;
			}

			@media (max-width: $md) {
				font-size: 2.8rem;
			}

			@media (max-width: $sm) {
				font-size: 2.6rem;
			}

			@media (max-width: $xs) {
				font-size: 2.4rem;
			}

			@media (max-width: 479px) {
				font-size: 2.2rem;
			}
		}

		
		h1 {
			padding: 0;
			margin: 0;
			text-transform: uppercase;
			font-size: 4.4rem;
			color: #fff;
			font-weight: 300;

			span {
				color: $main-color;
			}

			@media (max-width: $lg) {
				font-size: 4.2rem;
			}


			@media (max-width: $md) {
				font-size: 3.7rem;
			}

			@media (max-width: $sm) {
				font-size: 3.5rem;
			}

			@media (max-width: $xs) {
				font-size: 3.3rem;
			}

			@media (max-width: 479px) {
				font-size: 2.9rem;
			}

		}

		p {
			margin: 0;
			margin-bottom: 3rem;
			font-weight: 700;
			text-transform: uppercase;
			color: $main-color;
			font-size: 4.8rem;

			@media (max-width: $lg) {
				font-size: 4.6rem;
			}

			@media (max-width: $md) {
				font-size: 4.1rem;
			}

			@media (max-width: $sm) {
				font-size: 3.9rem;
			}

			@media (max-width: $xs) {
				font-size: 3.7rem;
			}

			@media (max-width: 479px) {
				font-size: 3.3rem;
			}

		}

		.btn {
			margin-right: 1rem;
			font-size: 1.5rem;
			padding: 1.1rem 3.5rem;
			border: 2px solid $main-color;
			color: #fff;
			border-radius: 25px;
			text-transform: uppercase;
			letter-spacing: 1px;
			transition: 0.3s;

			&:hover {
				background-color: $main-color;
			}

			@media (max-width: $lg) {
				font-size: 1.4rem;
				padding: 1rem 3.3rem;
			}

			@media (max-width: $md) {
				padding: 0.9rem 3.2rem;
				font-size: 1.3rem;
			}

			@media (max-width: $xs) {
				font-size: 1.2rem;
				padding: 0.8rem 3rem;
			}

			@media (max-width: 345px) {
				font-size: 1.2rem;
				padding: 0.8rem 2.5rem;
			}

			@media (max-width: 320px) {
				font-size: 1.2rem;
				padding: 0.8rem 2.4rem;
			}
		}


	}
	
}


//////////
// About Us
//////////

.about-us {
	padding-top: 4rem;
	padding-bottom: 7rem;
	text-align: center;

	@media (max-width: $xs) {
		padding-top: 3rem;
	}



	h1 {
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-size: 3rem;
		color: $title-color;
		margin: 0 auto;
		margin-bottom: 1.2rem;
		margin-top: 2rem;

		@media (max-width: $md) {
			font-size: 2.8rem;
		}

		@media (max-width: $sm) {
			font-size: 2.5rem;
		}


	}

	.line {
		width: 95px;
		height: 2px;
		background-color: $main-color;
		display: block;
		margin: 0 auto;

		@media (max-width: $md) {
			width: 91px;
		}

		@media (max-width: $sm) {
			width: 86px;
		}

		@media (max-width: $xs) {
			width: 80px;
		}
	}

	p {
		width: 100%;
		max-width: 65%;
		font-size: 1.7rem;
		line-height: 27px;
		margin: 0 auto;
		color: $p-color;
		margin-top: 3.5rem;
		


			span {
				display: block;
				margin-top: 1rem;	
			}

			@media (max-width: $md) {
				max-width: 85%;
			}

			@media (max-width: $xs) {
				max-width: 100%;
				text-align: left;
			}
	}
}



//////////
// Posts
//////////


.info {
	padding-top: 4rem;
	padding-bottom: 9rem;
	text-align: center;



	@media (max-width: $lg) {
		.grid-cell-one-third {
			width: 33.33333%;
		}
	}

	@media (max-width: $md) {

		padding-bottom: 4rem;

		.grid {
			display: block;
		}
		.grid-cell-one-third {
			width: 100%;
			margin: 0 auto;
			margin-bottom: 5rem;
		}
	}



	h1 {
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-size: 3rem;
		color: $title-color;
		margin: 0 auto;
		margin-bottom: 1.2rem;
		margin-top: 2rem;

		@media (max-width: $md) {
			font-size: 2.8rem;
		}

		@media (max-width: $sm) {
			font-size: 2.6rem;
		}
	}

	.line {
		width: 95px;
		height: 2px;
		background-color: $main-color;
		display: block;
		margin: 0 auto;

		@media (max-width: $md) {
			width: 93px;
		}

		@media (max-width: $sm) {
			width: 87px;
		}
	}

	.grid {
		margin-top: 4.5rem;

		.info-title {
			h3 {
				color: $title-color;
				text-transform: uppercase;

				@media (max-width: $md) {
					font-size: 2.1rem;
				}

				@media (max-width: $sm) {
					font-size: 2rem;
				}

			
			}

			p {
				color: $p-color;
				width: 100%;
				font-size: 1.7rem;
				max-width: 100%;
				margin: 0 auto;
				margin-bottom: 1rem;

				@media (max-width: $lg) {
					max-width: 95%;
				}

				@media (max-width: $md) {
					max-width: 75%;

				}

				@media (max-width: $xs) {
					max-width: 90%;
				}

				@media (max-width: $xxs) {
					max-width: 85%;
					font-size: 1.6rem;
				}

			}

			a {
				margin-left: 0.2rem;
				color: $main-color;
				text-decoration: underline;
				display: none;

				@media (max-width: $md) {
					display: inline-block;
				}
			}

		}
		
	}

	.info-content {
		position: relative;
		width: 90%;
		max-width: 400px;
		margin: auto;
		overflow: hidden;

		@media (max-width: $md) {
			width: 100%;
			max-width: 60%;
		}

		@media (max-width: $sm) {
			max-width: 55%;
		}

		@media (max-width: $xs) {
			max-width: 70%;
		}

		@media (max-width: 479px) {
			max-width: 90%;
		}

		@media (max-width: $xxs) {
			max-width: 100%;
		}



		img {
			width: 100%;
			height: 180px;
			border-radius: 10px;

			@media (max-width: $md) {
				height: auto;
			}
		}

		.content-details {
			position: absolute;
			text-align: center;
			padding-left: 1rem;
			padding-right: 1rem;
			width: 100%;
			top: 50%;
			left: 50%;
			opacity: 0;
			transform: translate(-50%, -50%);
			transition: all 0.3s ease-in-out 0s;

			h3 {
				color: #fff;
				margin: 0;
				text-transform: uppercase;
				letter-spacing: 0.3px;
				font-size: 2.2rem;

				@media (max-width: $md) {
					font-size: 3.2rem;
				}

				@media (max-width: $sm) {
					font-size: 2.6rem;
				}

				@media (max-width: 479px) {
					font-size: 2rem;
					
				}
			}
		}

		&:hover .content-details {
			top: 50%;
			left: 50%;
			opacity: 1;
		}


		.content-overlay {
			background: rgba($main-color, 0.75);
			position: absolute;
			height: 97%;
			width: 100%;
			top: 0; right: 0; bottom: 0; left: 0;
			opacity: 0;
			transition: all 0.4s ease-in-out 0s;
			border-radius: 10px;

			@media (max-width: $md) {
				height: 98.3%;
			}

			@media (max-width: 792px) {
				height: 97.9%;
			}

			@media (max-width: 479px) {
				height: 97%;
			}

			@media (max-width: 479px) {
				height: 97.7%;
			}

			@media (max-width: $xxs) {
				height: 97.2%;
			}


		}

			&:hover .content-overlay {
				opacity: 1;
			}
	}
}


//////////
// Gnezdo
//////////

.maternal-home {
	text-align: left;
	background: url('../images/gnezdo-final.jpg') center center no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;

	.image {
		
		padding-top: 1rem;

			h2 {
				color: $main-color;
				text-transform: uppercase;
				letter-spacing: 0.5px;
				font-size: 3.5rem;

				@media (max-width: $md) {
					font-size: 3.3rem;
				}

				@media (max-width: $sm) {
					font-size: 3rem;
				}

				@media (max-width: 320px) {
					font-size: 2.8rem;
				}

			}

	}

	.content {
		text-align: left;
		padding-left: 3.5rem;
		padding-top: 4.3rem;
		padding-bottom: 5.2rem;
		border-radius: 15px;
		width: 50%;
		background-color: rgba(white, 0.85);
		margin-bottom: 5rem;

		@media (max-width: $lg) {
			width: 53%;
		}

		@media (max-width: $md) {
			width: 60%;
		}

		@media (max-width: $sm) {
			width: 75%;
		}

		@media (max-width: $xs) {
			width: 100%;
		}

		.line {
			width: 80px;
			height: 3px;
			background-color: $main-color;
			display: block;

			@media (max-width: $md) {
				width: 78px;
			}

			@media (max-width: $sm) {
				width: 71px;
			}


		}

		p {
			margin-top: 2rem;
			width: 100%;
			font-size: 1.6rem;
			max-width: 85%;
			color: $p-color;

			@media (max-width: $lg) {
				max-width: 85%;
			}

			@media (max-width: $md) {
				max-width: 80%;
			}

			@media (max-width: $sm) {
				max-width: 85%;
			
			}

			@media (max-width: 320px) {
				max-width: 95%;
			}

		

		}

		ul {
			margin-bottom: 4rem;
			width: 100%;
			max-width: 90%;

			@media (max-width: $xs) {
				margin-bottom: 3rem;
			}

		}

		li {
			color: $p-color;
			margin-top: 0.7rem;
			margin-left: 2.5rem;
			font-size: 1.6rem;

			@media (max-width: $md) {
				margin-top: 1rem;
				font-size: 1.55rem;
			}

			@media (max-width: 320px) {
				margin-left: 0rem;
			}

			.fa-circle {
				color: $main-color;
				margin-right: 1rem;
				font-size: 1rem;
				margin-bottom: 1px;
			}
		}

		.btn {
			position: relative;
			font-size: 1.5rem;
			padding: 1rem 6rem;
			color: #fff;
			box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
			border-radius: 25px;
			text-transform: uppercase;
			letter-spacing: 1px;
			transition: 0.3s;
			background-color: $main-color;


			&:hover {
				top: -0.75px;
				background-color: rgba($main-color, 0.88);
				box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
				

			}

			@media (max-width: $md) {
				padding: 1rem 5.8rem;
				font-size: 1.4rem;
			}

			@media (max-width: $xs) {
				padding: 0.9rem 5.2rem;
				font-size: 1.35rem;

			}
		}

	}
}



//////////
// Call us
//////////

.call-us {
	background-color: $main-color;
	padding-top: 3rem;
	padding-bottom: 3rem;
	text-align: left;

	@media (max-width: $sm) {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	@media (max-width: $xs) {
		text-align: center;
	}

	.call-us-content {
		padding-left: 7rem;
		padding-right: 7rem;
		display: flex;
		letter-spacing: 0.8px;

		@media (max-width: $md) {
			padding-left: 5rem;
			padding-right: 5rem;
		}

		@media (max-width: $sm) {
			padding-left: 4rem;
			padding-right: 4rem;
		}

		@media (max-width: $xs) {
			display: block;
		}
	

		h2 {
			margin: 0;
			margin-right: auto;
			text-transform: uppercase;
			color: #fff;
			font-weight: 300;
			font-size: 3.2rem;

			@media (max-width: $lg) {
				font-size: 3rem;
			}

			@media (max-width: $md) {
				font-size: 2.8rem;
			}

			@media (max-width: $sm) {
				font-size: 2.2rem;
			}

			@media (max-width: $xs) {
				font-size: 2.5rem;
				margin-bottom: 1rem;
			}

			@media (max-width: 479px) {
				margin-bottom: 0;
			}



		}

		span {
			text-transform: uppercase;
			color: #fff;
			font-weight: 300;
			font-size: 3.2rem;

			@media (max-width: $lg) {
				font-size: 3rem;
			}

			@media (max-width: $md) {
				font-size: 2.6rem;
			}

			@media (max-width: $sm) {
				font-size: 2.1rem;
			}

			@media (max-width: $xs) {
				font-size: 2.4rem;
			}

			strong {

				@media (max-width: 479px) {
					font-size: 2.2rem;
					display: block;
					text-decoration: underline;
				}
			}
		}

	}
	
	}

}


//////////
// Footer
//////////


.footer {
	background-color: lighten($footer-color, 10%);
	width: 100%;
	padding-top: 4rem;

	@media (max-width: $md) {
		.grid {
			display: flex;
			flex-wrap:wrap;
			text-align: center;

			@media (max-width: $sm) {
				text-align: left;
				margin: 0 auto;

				.col-1 {
					order: 2;
					
				}

				.col-2 {
					order: 1;

				}

				.col-3 {
					order: 1;
				}

				.col-4 {
					order: 2;
					
				}
			}

			@media (max-width: 479px) {

				.col-1 {
					order: 2;
					padding-left: 2rem;
				}

				.col-2 {
					order: 1;
				}

				.col-3 {
					order: 3;
				}

				.col-4 {
					order: 4;
					padding-left: 2rem;
				}
			}

			@media (max-width: 320px) {

				.col-1 {
					order: 2;
					padding-left: 1.5rem;
				}

				.col-4 {
					order: 4;
					padding-left: 1rem;
				}
			}

			.col-1 {
				width: 100%;
				text-align: center;
				padding-bottom: 5rem;
				border-bottom: 1px solid $footer-color;

				h3 {
					font-size: 2.5rem;
					margin: 0;
					margin-bottom: 1.5rem;
				}

				@media (max-width: $sm) {
					width: 50%;
					border-bottom: none;
					text-align: left;
					padding-bottom: 0;

					h3 {

						font-size: 1.755rem;
						margin-top: 1.755rem;
					}
				}

			}
		}
	}

	.grid-cell {
		width: calc(25% - 10px);
		margin-bottom: 7rem;

		@media (max-width: $lg) {
			width: 25%;
		}

		@media (max-width: $md) {
			display: inline-block;
			width: 32%;
			margin: 0 auto;
			margin-bottom: 2.5rem;
			vertical-align: top;
		}

		@media (max-width: $sm) {
			width: 50%;
		}

	}

	.footer-content {

		.btn {
			font-weight: 700;
			font-size: 1.35rem;
			padding: 1rem 3.2rem;
			color: $footer-color;
			background-color: #fff;
			box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
			border-radius: 25px;
			text-transform: uppercase;
			letter-spacing: 1px;
			transition: 0.3s;


			&:hover {
				background-color: rgba(white, 0.75);
				box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
				

			}

			@media (max-width: 479px) {
				font-size: 1.2rem;
				padding: 0.9rem 3rem;
			}

			@media (max-width: 479px) {
				font-size: 1.2rem;
				padding: 0.8rem 2.5rem;
			}
		}

		h3 {
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 1.2px;
		}

		p {
			color: #fff;
			margin-bottom: 2.5rem;
			width: 100%;
			max-width: 75%;
			
			@media (max-width: $md) {
				font-size: 2rem;
				font-weight: 300;
				margin: 0 auto;
				margin-bottom: 2.5rem;
			}

			@media (max-width: $sm) {
				font-size: 1.6rem;
				margin: 0;
				margin-bottom: 2rem;
			}

			@media (max-width: $xxs) {
				max-width: 100%;
			}
		}

		ul {
			li {

					margin-top: 0.5rem;
					margin-bottom: 0.8rem;

					@media (max-width: $xxs) {
						margin-top: 0.8rem;
						margin-bottom: 1.2rem;
					}


					a {
						position: relative;
						color: #fff;
						letter-spacing: 0.6px;
						margin-bottom: 1rem;
						
						.social {
							margin-right: 3rem;

							&:hover {
								text-decoration: none;
							}
						}


						&:before {
							content: "";
							position: absolute;
							width: 100%;
							height: 2px;
							bottom: -15%;
							left: 0;
							background-color: #fff;
							visibility: hidden;
							transform: scaleX(0);
							transition: all 0.3s ease-in-out 0s;
						}

						&:hover:before {
							visibility: visible;
							transform: scaleX(1);
						}
					}
				}
			}
		}

		.copyright {
			text-align: center;
			padding-top: 2.5rem;
			padding-bottom: 2.5rem;
			background-color: $footer-color;


			p {
				margin: 0;
				color: #fff;

				a {
					position: relative;
					color: #fff;
					letter-spacing: 0.6px;
					margin-bottom: 1rem;

					&:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 2px;
						bottom: -15%;
						left: 0;
						background-color: #fff;
						visibility: hidden;
						transform: scaleX(0);
						transition: all 0.3s ease-in-out 0s;
					}

					&:hover:before {
						visibility: visible;
						transform: scaleX(1);
					}
				}
			}
		}

		.social {
			

			h3 {
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 1.2px;
			}

			li {

				margin-top: 1.5rem;

				@media (max-width: $md) {
					text-align: left;
					padding-left: 6rem;
				}

				@media (max-width: $sm) {
					
					padding-left: 0;
				}



				a {
					color: #fff;

					&:hover {
						color: rgba(white, 0.75);
					}
					
					@media (max-width: $xxs) {
						
						font-size: 1.4rem;
					}


					.social-icon {
						width: 20px;
						height: 20px;
						margin-right: 2.5rem;
						color: $footer-color;

						@media (max-width: $lg) {
							margin-right: 2rem;

						}

						@media (max-width: 320px) {
							margin-right: 0.5rem;

						}

					}

				}
			}
			
		}
	}





//////////
// 					OTHER PAGES
//////////


//////////
// Da li sam trudna?
/////////

#page-1 {


	.navbar {

		background-color: #fff;
		box-shadow: 0 4px 2px -2px gray;
			
			.logo {
				color: $main-color;
			}

			nav {
				ul {
					li {
						a {

							color: $main-color;
							&:hover {
								color: rgba($main-color, 0.7);
							}
						}

						ul {
							li {
								a {

									color: #fff;

									&:hover {
										color: $main-color;
									}
								}
							}
						}
					}
				}
			}		
	}

	.content {

		text-align: left;
		padding-top: 5rem;


		h1 {
			margin: 0;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			font-size: 3rem;
			color: $title-color;
			margin: 0 auto;
			margin-bottom: 1.2rem;
			margin-top: 2rem;

			@media (max-width: 479px) {
				font-size: 2.8rem;
			}
			
		}

		.line {
			width: 95px;
			height: 2px;
			background-color: $main-color;
			display: block;

			@media (max-width: 479px) {
				width: 93px;
			}
		}

		.content-wrapper {
			margin-top: 4rem;
			padding-bottom: 15rem;

			.content-image {
				float: right;
				margin-left: 2rem;
				margin-bottom: 2rem;
				max-width: 100%;

				@media (max-width: $lg) {
					margin-bottom: 0.5rem;
				}

				@media (max-width: $sm) {
					float: none;
					margin-left: 0;
				}

				img {
					border-radius: 10px;
					width: 500px;
					height: auto;

					@media (max-width: $md) {
						width: 420px;
					}

					@media (max-width: $sm) {
						width: 100%;
						text-align: left;
					}

				}
			}

			.content-text {


				h1 {
					margin-top: 5rem;

					@media (max-width: 479px) {
						font-size: 2.8rem;
					}
				}

				h2 {
					color: $title-color;
					text-transform: uppercase;
					margin-top: 2rem;
				}

				h3 {
					color: $title-color;
					text-transform: uppercase;
					margin-top: 4rem;
				}

				.col {
					width: 49%;
					display: inline-block;

					h3 {
						margin-top: 1.5rem;
					}

					@media (max-width: $xs) {
						width: 100%;
					}
				}

				.quote {

					span {
						text-align: center;

						@media (max-width: $sm) {
							text-align: left;
						}
						.quotes {
							color: $main-color;
							margin-right: 2rem;
							margin-left: 2rem;
						}
					}

					span:not(:last-child) {
						margin-top: 4rem;
						font-size: 2rem;
					}

					span:not(:first-child) {
						text-align: right;
						margin-top: 2rem;
						font-size: 1.8rem;
					}
				}

	////////// Lista sa stranice Simptomi Trudnoce i test za trudnocu

				.contact-social {
					li {
						margin-left: 0;
						margin-top: 1.5rem;
						margin-bottom: 1rem;
						text-align: left;

						.social {
							margin-right: 2rem;
							width: 24px;
							height: 24px;
							color: $main-color;
						}

						a {
							text-decoration: none;
							color: $p-color;
							position: relative;

							&:before {
								content: "";
								position: absolute;
								width: 100%;
								height: 2px;
								bottom: -15%;
								left: 0;
								background-color: $main-color;
								visibility: hidden;
								transform: scaleX(0);
								transition: all 0.3s ease-in-out 0s;
							}

							&:hover:before {
								visibility: visible;
								transform: scaleX(1);
							}
						}
					}
				}

					ul {
						margin-bottom: 2rem;
						width: 100%;
						max-width: 100%;

						li {
							color: $p-color;
							margin-top: 0.7rem;
							margin-left: 2.8rem;
							font-size: 1.6rem;
							line-height: 27px;

							@media (max-width: $xxs) {
								margin-left: 0;
							}

								.fa-circle {
									color: $main-color;
									margin-right: 1rem;
									font-size: 1rem;
									margin-bottom: 1px;
								}

								
							}
						}

				////////////

				p {
					width: 100%;
					max-width: 100%;
					font-size: 1.7rem;
					line-height: 27px;
					color: $p-color;

					span {
						display: block;
						margin-top: 1rem;

						a {

							text-decoration: underline;
							color: $main-color;
						}
					}
				}

				a {
				text-decoration: underline;
				color: $main-color;
				font-size: 1.7rem;
			
				}
			}
		}

	}

	/////////
	// Contact form
	/////////

	.contact-form {

		padding-top: 2rem;
		text-align: left;
		//text-align: center;

		h2 {
			color: $title-color;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			font-size: 2.6rem;
		}
	}

	.form-container {
		padding-top: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 100%;
		max-width: 60%;
		text-align: left;
		//margin: 0 auto;

		@media (max-width: $lg) {
			max-width: 65%;
		}

		@media (max-width: $md) {
			max-width: 75%;
		}

		@media (max-width: $sm) {
			max-width: 95%;
		}

		@media (max-width: $xs) {
			max-width: 100%;
		}


		.row {
			display: flex;
			justify-content: space-between;
			height: 50px;
			width: 80%;
			margin-bottom: 1.5rem;
			//margin: 0 auto;

			@media (max-width: $xs) {
				width: 95%;
			}

			@media (max-width: 479px) {
				width: 95%;
				display: inline-block;
			}




			.cell {
				display: inline-block;
				width: 48%;

				@media (max-width: 479px) {
					width: 100%;
				}




				input[name="name"],
				input[name="usrphone"],
				input[name="_replyto"] {
					width: 100%;
					height: 45px;
					border: 2px solid $main-color;
					border-radius: 7px;
					background-color: #fff;
				}

				@media (max-width: 479px) {
					width: 100%;
					margin-bottom: 5rem;
					
				}

				input[name="name"]::placeholder,
				input[name="usrphone"]::placeholder,
				input[name="_replyto"]::placeholder {
					color: $main-color;
					font-size: 1.7rem;
				}
			}
		}

		.message {
			width: 80%;
			//margin: 0 auto;
			//text-align: center;

			@media (max-width: $xs) {
				width: 95%;
			}


			@media (max-width: 479px) {
				width: 95%;
				margin-top: 13rem;
			}



			textarea {
				width: 100%;
				min-height: 160px;
				border: 2px solid $main-color;
				background-color: #fff;
				resize:none;

			}

			textarea::placeholder {
				color: $main-color;
				font-size: 1.7rem;
			}

			input[type="submit"] {
				border-style: none;
				margin-top: 0rem;
				margin-bottom: 2.5rem;
				cursor: pointer;
				font-size: 1.5rem;
				padding: 1.2rem 5.5rem;
				color: #fff;
				box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
				border-radius: 25px;
				text-transform: uppercase;
				letter-spacing: 1px;
				transition: 0.3s;
				background-color: $main-color;


				&:hover {
				
					background-color: rgba($main-color, 0.88);
					box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
					

				}
			}




			
		}
	}

}





	








