@import "_variables.scss";

////////////////////////////////////////////////

// GRID

////////////////////////////////////////////////

  /* clears children */

.group:after {
  content: "";
  display: table;
  clear: both;
}


.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px; }





.grid {
  display: flex;
  justify-content: space-between;
}

.grid-cell-one-third {
  width: calc(33.3333% - 30px);
}