/************ 
RESPONSIVE
*************/ 


.mobilemenupopup {
  display: none;
}

@media (max-width: 991px) {

  .mobilemenupopup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 65vw;
    padding: 20px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    height: 100vh;
  }

  .mobilemenupopup ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .mobilemenupopup ul li > ul {
    font-size: 12px;
  }

  .mobilemenupopup ul li > ul li {
    padding-left: 20px;
  }

  .mobilemenupopup ul li {
  }

  .mobilemenupopup ul li a {
    position: relative;
    background: transparent;
    text-decoration: none;
    font-size: 15px;
    display: block;
    color: #333333;
    padding: 15px;
    transition: all ease-in-out 200ms;
  }

  .mobilemenupopup {
    ul {
      
      li {

        &.has-submenu {
          > a:after {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: calc(50% - 5px); right: 10px;
            opacity: 0.7;
            background-image: url('../images/down.svg');
            background-size: 100% 100%;
            transition: all ease-out 0.3s;
          }

          &.active {
            > a:after {
              transform: rotate(180deg);
            }
          }
        }

        ul {
          display: none;

          
        }
      }
    }
  }

  .mobilemenupopup ul li a i.fa {
    margin-right: 6px;
  }

  .mobilemenupopup ul li a:hover {
    background: #eee;
  }

  .mobilemenupopup ul li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  .page-wrap {
    transition: all ease-in-out 200ms;
    z-index: 999999;
    background: white;
    overflow: hidden;
  }

  .page-wrap .page-wrap-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 9999999;
  }

  .page-wrap.move {
    -webkit-transform: translate(65%, 0px);
    transform: translate(65%, 0px);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  }
}
@media (min-width: 992px) {


  .mobilemenupopup {
    display: none !important;
  }
}
.mobile-menu-trigger {
  width: 28px;
  height: 25px;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.mobile-menu-trigger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.mobile-menu-trigger span:nth-child(1) {
  top: 3px;
}

.mobile-menu-trigger span:nth-child(2) {
  top: 11px;
}

.mobile-menu-trigger span:nth-child(3) {
  top: 19px;
}

.mobile-menu-trigger.open span:nth-child(1) {
  top: 12px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mobile-menu-trigger.open span:nth-child(2) {
  opacity: 0;
}

.mobile-menu-trigger.open span:nth-child(3) {
  top: 12px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
